import React from 'react'
import Slider from "react-slick";
import CategoryCard from './CategoryCard';





function Category({products, category_id}) {
  let productCount = products.length
  let gridCount = 0;

  if(productCount > 4) {
    gridCount = 4
  }else{
    gridCount = productCount
  }


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: gridCount,
    className: 'category-slider',

    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='mt-4'>
          <Slider {...settings} style={{
        marginRight: 'auto', marginLeft: 'auto'}}>

        {
            products.map((item, index) => (
              <CategoryCard key={index} id={category_id} name = {item.name} description = {item.description} img = {item.img} index = {index} />
            )
            )
        }
        </Slider>

    </div>
  )
}

export default Category