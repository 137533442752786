import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import parse from 'html-react-parser'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img className="modal-img" src={props.img} />
        <p style={{fontSize: '1.5em'}}>
          {parse(props.description)}
        </p>
      </Modal.Body>

    </Modal>
  );
}




function BarbekuCard({ name, img, description, index, id }) {

  const [modalShow, setModalShow] = useState(false);

  let bgColor = '#FFCDD2'
  if((index % 4) == 0) {
    bgColor = '#B2DFDB'
  }else if((index % 4) == 1) {
    bgColor = '#C5CAE9'  
  }else if((index % 4) == 2) {
    bgColor = '#B3E5FC'  
    
  }else {
    bgColor = '#FFCDD2'
  }

  return (
    <div>
        <Card 
      id={"category"+id}
      className="category-card"
      style={{
        width: "15rem",
        height: "18rem",
        objectFit: "scale-down",
        borderRadius: "20px",
        marginBottom: "20px",
        marginRight: "auto",
        marginLeft: "auto",
        cursor: 'pointer',
        background: bgColor

      }}
      onClick={() => setModalShow(true)}

    >
      <Card.Img
      
        variant="top"
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "20px 20px 20px 20px"
        }}
        src={img}
      />

      
    </Card>

    <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        name={name}
        img = {img}
        description = {description}
      /> 



    </div>
    

   
  );
}

export default BarbekuCard;
