import { SlLocationPin } from "react-icons/sl";
import { GoMailRead } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";

function HeaderSocial() {
  return (
    <div className="d-none d-lg-flex flex-column justify-content-between flex-md-fill header-social header-adress align-items-center">
    <div className="d-flex flex-col">
      <div className="d-none d-lg-flex flex-row col align-items-center">
        <div className="fs-3 ">
          <SlLocationPin />
        </div>
        <div className="ps-3">
          <p className="fs-6 text-secondary m-0">Adres</p>
          <p className="fs-6 m-0 fw-semibold text-dark">
            Nizip Yolu 10. Km Gaziantep
          </p>
        </div>
      </div>

      <div className="d-none d-lg-flex flex-row col border-end border-start align-items-center ">
        <div className="fs-3 ps-3">
          <GoMailRead />
        </div>
        <div className="ps-3">
          <p className="fs-6 text-secondary m-0 md-fs-4">Email</p>
          <a
            href="mailto:info@kanar.com.tr"
            style={{ textDecoration: "none" }}
          >
            <p className="fs-6 m-0 fw-semibold text-dark ">
              info@kanar.com.tr
            </p>
          </a>
        </div>
      </div>

      <div className="d-none d-lg-flex flex-row col align-items-center ">
        <div className="fs-3 md-fs-6 ps-3">
          <FiPhoneCall className="phone-icon"/>
        </div>
        <div className="ps-3">
          <p className="fs-6 text-secondary m-0">İletişim</p>
          <a
            href="tel:+90-533-448-0585"
            style={{ textDecoration: "none" }}
          >
            <p className="fs-6 m-0 fw-semibold text-dark">
              +90 533 448 0585
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
  )
}

export default HeaderSocial