import Carousel from "react-bootstrap/Carousel";
import Card from "./Card";
import data from "./MainProducts.json";


function MainSlider() {

  return (
    <div className="d-flex justify-content-center main-area mt-5 main-slider">
      <Carousel fade interval={500000000}>
                {data.map( item => <Carousel.Item> <Card
                    key={item._id}
                    name={ item.name }
                    description= { item.description }
                    img = {item.img}
                ></Card></Carousel.Item>)}
            </Carousel>
    
    
      </div>
  );
}

export default MainSlider;
