import React from 'react'

function HeaderLogo() {
  return (
    <div className="header-logo">
    <div>
      <a href="/">
        <img
          src="images/logo.jpg"
          className="logo-img"
          alt='/'
          style={{ width: "100%", height: "auto" }}
        />
      </a>
    </div>
  </div>
  )
}

export default HeaderLogo