import React from "react";
import HeaderLogo from "./HeaderLogo";
import FirstNavArea from "./FirstNavArea";
import HeaderSocial from "./HeaderSocial";
import Dropdownbutton from "./Dropdownbutton";

function Navbar() {
  return (
    <div>
      <FirstNavArea />

      <div className="d-flex flex-column py-3 px-2"
      style={{ backgroundColor: "white" }}
      >
        <div  
          className="d-flex flex-row justify-content-center justify-content-lg-between "
        >
          <HeaderLogo />
          <HeaderSocial className="d-none"/>
        </div>


        {/* Dropdown */}
        <div className="d-flex header-dropdown">
          <div
            style={{ width: "100%" }}
            className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-end align-items-center"
          >
            {/* <p className="fs-6 m-0 fw-semibold text-dark pe-2">
              Ürün Kategorileri
            </p> */}
           
           <Dropdownbutton />
          </div>
        </div>
        {/* Dropdown */}
      </div>
    </div>
  );
}

export default Navbar;


{/* <DropdownButton
                id="dropdown-basic-button"
                title="Ürün Kategorileri"
                style={{ z: "1"}}
                className = "btn-custom"
              >
                <Dropdown.Item  href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </DropdownButton> */}