import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import parse from 'html-react-parser'

import imgtemp from '../../../assets/kirec/sonmus-kaymak-badana.jpg'

function CategoryCard({ name, img, description, index, id }) {


  let bgColor = '#FFCDD2'
  if((index % 4) == 0) {
    bgColor = '#B2DFDB'
  }else if((index % 4) == 1) {
    bgColor = '#C5CAE9'  
  }else if((index % 4) == 2) {
    bgColor = '#B3E5FC'  
    
  }else {
    bgColor = '#FFCDD2'
  }

  return (
    <Card 
      id={"category"+id}
      className="category-card"
      style={{
        width: "15rem",
        height: "18rem",
        objectFit: "scale-down",
        borderRadius: "20px",
        marginBottom: "20px",
        marginRight: "auto",
        marginLeft: "auto",
        background: bgColor

      }}
    >
      <Card.Img
        variant="top"
        style={{
          width: "100%",
          height: "50%",
          borderRadius: "20px 20px 0 0"

        }}
        src={img}
      />
      <Card.Body className="card-body"
        style={{ borderRadius: "0 0 20px 20px", background: bgColor}}
      >
        <Card.Title>{name}</Card.Title>
        <Card.Text>{parse(description)}</Card.Text>
      </Card.Body>
      
    </Card>

   
  );
}

export default CategoryCard;
