import React from "react";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaPhoneSquareAlt,
} from "react-icons/fa";

import data from './info.json'


function Footer() {

  return (
    <div className="footer-area">
      <div className="d-flex justify-content-center px-3">
        <p className="text-center pt-5">
          {data.text}
        </p>
      </div>

      <div className="border mx-5 my-2"></div>

      <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center mx-5 mt-5 pb-5">
        <p className='footer-adress'>
          Adres: Gaziantep Nizip Yolu 10.km No: Bila Şehitkamil/Gaziantep
        </p>

        <div className="d-flex flex-column footer-phone-social">
          <p>
            Gsm: +90 533 448 0585
          </p>
          <div>
            <div className="d-flex flex-row justify-content-between pb-2 footer-icon gap-1">
              <div>
                <a href="/facebook">
                  <FaFacebookSquare style={{  color: 'white', minHeight: '64px' }} />
                </a>
              </div>
              <div>
                <a href="/twitter">
                  <FaTwitterSquare style={{ color: 'white', minHeight: '64px' }} />
                </a>
              </div>
              <div>
                <a href="/instagram">
                  <FaInstagramSquare style={{  color: 'white', minHeight: '64px' }} />
                </a>
              </div>

              <div>
                <a href="/instagram">
                  <FaPhoneSquareAlt style={{ color: 'white', minHeight: '64px' }}  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="map-container-google-2" className="z-depth-1-half map-container">
        <div className="ratio footer-map">
          <embed
            type="image/svg+xml"
            src="https://maps.google.com/maps?q=37.07147034621169,%2037.48170760000001&t=&z=13&ie=UTF8&iwloc=&output=embed"
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
