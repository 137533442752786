import { Nav } from "react-bootstrap";
import {Link} from "react-router-dom";
import {
    FaFacebookSquare,
    FaWhatsappSquare,
    FaInstagramSquare,
  } from "react-icons/fa";

function FirstNavArea() {
  return (
    <Nav
    className="d-flex flex-column align-items-center justify-content-center flex-md-row  justify-content-md-between px-3 py-3 dark-nav"

  >
    <Nav.Item>
      {" "}
      <a
        className="nav-phone"
        href="tel:+90-533-448-0585"
        style={{ color:  'rgb(211, 47, 47)', textDecoration: "none" }}
      >
        Hemen Arayın: <strong>+90 533 448 0585</strong>
      </a>
    </Nav.Item>
    <div>
      <div className="d-flex flex-row align-items-center justify-content-center">
        <div>
          <a href="https://www.facebook.com/KanarBimsIzalasyonluHafifDuvarYapiElemanlari/">
            <FaFacebookSquare style={{ color: "white" }} size={35} />
          </a>
        </div>
        <div>
          <a href="https://instagram.com/gaziantepbarbeku?igshid=YmMyMTA2M2Y=">
            <FaInstagramSquare style={{ color: "white" }} size={35} />
          </a>
        </div>
        <div>
          <a href="http://wa.me/905334480585">
            <FaWhatsappSquare style={{ color: "white" }} size={35} />
          </a>
        </div>
      </div>
    </div>
  </Nav>

  )
}

export default FirstNavArea