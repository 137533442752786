import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Card from "./Card";
import data from "./MainProducts.json";


function YeniSlider() {
  return (
    <div className='main-slider'>
        <Carousel fade interval={500000000} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                {data.map( (item, index) => <Carousel.Item key={index} >
                  <div style={{width: '100%', background: 'pink'}}>
                    <Card
                    key={item._id}
                    name={ item.name }
                    description= { item.description }
                    img = {item.img}
                ></Card>
                    
                    </div> </Carousel.Item>)}
            </Carousel>
    </div>

    
  )
}

export default YeniSlider

