import React from 'react'
import barbeku from '../../assets/barbeku1.jpg'
import data from './MainProducts.json'
import parse from 'html-react-parser'
import { GrClose } from 'react-icons/gr'


function Card({name, description, img}) {

  return (
    <div className='d-flex flex-row justify-content-between row card main-slider-card'>
        <div style={{width: '50%', height:'100%'}} className='col-6 p-0'>
            <img  src={img} alt='/'/>
        </div>
        <div className='col-6 card-content-area p-0' style={{width: '50%'}}>
            <div className='p-3'>
            <h5 className='mb-3'>{name}</h5>
            <p>{parse(description)}</p>
            </div>
            
        </div>
        
    </div>
  )
}

export default Card