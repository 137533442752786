import NavDropdown from 'react-bootstrap/NavDropdown';
import data from '../../Products.json'
import BarbekuCard from '../Products/Barbeku/BarbekuCard';

import barbekudata from '../Products/Barbeku.json'

function Dropdownbutton() {


  return (
    <div className='custom-drop-btn'>
    <NavDropdown
      id="nav-dropdown-dark-example"
      title="Ürünlerimiz"
      menuVariant="dark"
      style={{color: 'white'}}
    >

<NavDropdown.Item href={"#category"+barbekudata.category_id}>{barbekudata.category_name}</NavDropdown.Item>
      {
        data.map((item, index) => (
          <NavDropdown.Item key={index} href={"#category"+item.category_id}>{item.category_name}</NavDropdown.Item>

        ))
      }
    </NavDropdown>
      
    </div>
  )
}

export default Dropdownbutton