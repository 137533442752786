
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from './components/Navbar'
import MainSlider from './components/MainSlider'
import Products from './components/Products'
import Footer from './components/Footer'
import YeniSlider from './components/MainSlider/YeniSlider';

function App() {
  return (
    <div className="App">
      <Navbar />
      <YeniSlider />
       {/* <MainSlider /> */}
    <Products />
      <Footer /> 
    </div>
  );
}

export default App;
