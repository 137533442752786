import Category from "./Category";
import data from '../../Products.json'
import barbekudata from './Barbeku.json'
import BarbekuCards from "./Barbeku/BarbekuCards";


function Products() {




  return (
    <div className="px-5 py-5">

     <h2>ÜRÜNLERİMİZ</h2>
      <h5 className='my-5' style={{ color: '#D32F2F'}}>{barbekudata.category_name}</h5>
      <BarbekuCards products = {barbekudata.products} category_id = {barbekudata.category_id}/>
      {/* <Category products = {barbekudata.products} category_id = {barbekudata.category_id} /> */}
     {

      data.map((item) => (
        <>
         <h5 className='my-5' style={{ color: '#D32F2F'}}>{item.category_name}</h5>
         <Category products = {item.products} category_id = {item.category_id} />
        </>
      ))
     }

    </div>
  );
}

export default Products;
